/**
 * @fuegokit/tokens 0.24.0
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 12:21:54 GMT
 */
export default {
  fontFamily: {
    sansSerif: '-apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    monospace: "SF Mono",
    heading: '-apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    display: '-apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    body: '-apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    normal: '-apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    mono: "SF Mono",
  },
  fontStack: {
    sansSerif: '"Area Normal Regular", -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    monospace: "SF Mono",
    title: '"Area Normal Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`',
    body: '"Area Normal Regular", -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    normal: '"Area Normal Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`',
    mono: '"SFMono-Medium", "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Consolas, Courier, monospace',
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    semibold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  fontSize: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "14px",
    "4": "16px",
    "5": "18px",
    "6": "20px",
    "7": "24px",
    "8": "28px",
    "9": "29px",
    "10": "35px",
    "11": "36px",
    "12": "47px",
    "13": "62px",
  },
  lineHeight: {
    "0": "16px",
    "1": "20px",
    "2": "24px",
    "3": "28px",
    "4": "32px",
    "5": "36px",
    "6": "40px",
    default: 1.429,
    condensed: 1.143,
    condensedUltra: 1,
    relaxed: 1.714,
  },
  letterSpacing: {
    "0": "0px",
    "1": "-0.042px",
    "2": "-0.084px",
    "3": "-0.112px",
    "4": "-0.14px",
  },
  font: {
    heading: {
      xxsmall: '600 12px/16px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      xsmall: '600 14px/20px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      small: '600 16px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      medium: '600 20px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      large: '600 24px/28px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      xlarge: '600 28px/32px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      xxlarge: '600 36px/40px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    },
    body: {
      "[default]": '500 14px/20px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      small: '500 12px/16px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      medium: '500 14px/20px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      large: '500 16px/24px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      xlarge: '500 18px/28px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    },
    ui: {
      "[default]": '600 14px/16px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      small: '600 12px/16px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    },
    caption: {
      "[default]": 'normal 500 12px/20px -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    },
    code: {
      "[default]": "400 14px SF Mono",
      xsmall: "400 12px SF Mono",
      small: "400 14px SF Mono",
      medium: "400 16px SF Mono",
    },
    display: {
      "[default]": '300 62px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      medium: '300 47px -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
    },
  },
};
