import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { ThemeProvider, SSRProvider, Tooltip } from "@fuegokit/react";

import { H1, H2, H3, H4, H5, H6 } from "./Heading";
import { Link } from "./Link";
import { Code, InlineCode } from "./Code";
import CopyToClipboard from "./CopyToClipboard";
import { Details, Summary } from "./Details";
import { Paragraph } from "./Paragraph";
import { InfoBox } from "./InfoBox";
import { ImageCaption, CaptionTitle } from "./ImageCaption";
import { HorizontalRule } from "./HorizontalRule";
import { ImageWrapper, VideoWrapper } from "./ImageWrapper";
import { Table } from "./Table";
import { TextInput } from "./TextInput";
import { Image } from "./Image";
import { Blockquote } from "./Blockquote";
import { DescriptionList } from "./DescriptionList";
import { UnstyledButton } from "./UnstyledButton";
import { ResourceLink, ResourcesList } from "./ResourcesList";
import StatusLabel from "./StatusLabel";
import { List } from "./List";
import { Do, Dont, DoDontComponent } from "./DoDont";
import {
  DoDontChecklist,
  DoDontChecklistItem,
  DoDontChecklistHeading,
  DoDontChecklistLink,
} from "./DoDontChecklist";
import { ResourceCard, ResourceCardGrid } from "./ResourceCard";
import { A11yLink } from "./A11yLink";
import SourceLink from "./SourceLink";
import FigmaLink from "./FigmaLink";
import StorybookLink from "./StorybookLink";
import { VercelAnalytics } from "./VercelAnalytics";
import ComponentProps from "./ComponentProps";
import BackButton from "./BackButton";
import mdxComponents from "../mdx-components";

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  a: Link,
  img: Image,
  p: Paragraph,
  hr: HorizontalRule,
  inlineCode: InlineCode,
  table: Table,
  blockquote: Blockquote,
  pre: (props) => props.children,
  code: Code,
  ul: List,
  ol: List.withComponent("ol"),
  dl: DescriptionList,
  details: Details,
  summary: Summary,
  ResourcesList,
  ResourceLink,
  ResourceCard,
  ResourceCardGrid,
  ImageWrapper,
  ImageCaption,
  CaptionTitle,
  InfoBox,
  StatusLabel,
  Do,
  Dont,
  DoDontComponent,
  DoDontChecklist,
  DoDontChecklistItem,
  DoDontChecklistHeading,
  DoDontChecklistLink,
  UnstyledButton,
  FigmaLink,
  SourceLink,
  A11yLink,
  StorybookLink,
  CopyToClipboard,
  TextInput,
  VercelAnalytics,
  Link,
  ComponentProps,
  BackButton,
  ...mdxComponents,
};

function wrapRootElement({ element }) {
  return (
    <SSRProvider>
      <MDXProvider components={components}>
        <Tooltip.Provider>
          <ThemeProvider colorMode="auto" preventSSRMismatch>
            {element}
          </ThemeProvider>
        </Tooltip.Provider>
      </MDXProvider>
    </SSRProvider>
  );
}

export default wrapRootElement;
