import React from "react";
import { Box, Text } from "@fuegokit/react";
import {
  AkCheckCircleIcon,
  AkCrossCircleIcon,
} from "@fuegokit/fuegoicons-react";

export const DoDontComponent = ({
  vertical,
  docsTheme = "default",
  children,
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", null, vertical ? "1fr" : "1fr 1fr"],
        gridGap: 4,
        mb: 2,
      }}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { docsTheme })
      )}
    </Box>
  );
};
export const Do = ({ variant, docsTheme = "default", ...props }) => {
  return (
    <BaseDoDontComponent
      {...props}
      variant={variant}
      title="Do"
      docsTheme={docsTheme}
      background="background.success.bold.default"
      borderColor="border.success"
    />
  );
};

export const Dont = ({ variant, docsTheme = "default", ...props }) => {
  return (
    <BaseDoDontComponent
      {...props}
      variant={variant}
      title="Don't"
      docsTheme={docsTheme}
      background={"background.danger.bold.default"}
      borderColor="border.danger"
    />
  );
};

const BaseDoDontComponent = ({
  title,
  borderColor,
  background,
  children,
  variant,
  ...props
}) => {
  const { docsTheme } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 3,
      }}
    >
      {variant === "blockquote" ? (
        <BlockquoteBase borderColor={borderColor}>{children}</BlockquoteBase>
      ) : variant === "border" ? (
        <BorderTop
          borderColor={borderColor}
          background={background}
          docsTheme={docsTheme}
        >
          {children}
        </BorderTop>
      ) : !variant ? (
        <DefaultBase
          borderColor={borderColor}
          title={title}
          background={background}
          docsTheme={docsTheme}
        >
          {children}
        </DefaultBase>
      ) : null}
    </Box>
  );
};

const BlockquoteBase = ({ children, borderColor }) => {
  return (
    <Box
      as="blockquote"
      sx={{
        margin: 0,
        borderLeftWidth: "space.1",
        borderLeftStyle: "solid",
        borderLeftColor: borderColor,
        paddingLeft: 3,
      }}
    >
      <Text
        as="span"
        sx={{
          color: "text.subtle",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif",
        }}
      >
        {children}
      </Text>
    </Box>
  );
};

const DefaultBase = ({
  children,
  borderColor,
  docsTheme,
  title,
  background,
}) => {
  let textColor = "text.inverse";

  if (background.includes("success") || background.includes("danger")) {
    textColor = "text.inverse";
  } else {
    textColor = "text.default";
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          backgroundColor: background,
          color: "text.default",
          px: 3,
          py: 3,
          borderRadius: 1,
        }}
      >
        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 3,
            color: textColor,
          }}
        >
          {title}
        </Text>
      </Box>
      <Box
        sx={{
          "& *:last-child": { mb: 0 },
          img: { maxWidth: "100%" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </>
  );
};

const BorderTop = ({ children, borderColor, background, docsTheme }) => {
  console.log("this is docsTheme in border top: ", docsTheme);
  console.log("this is borderColor in BorderTop: ", borderColor);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          backgroundColor: borderColor,
          color: "text.default",
          px: 1,
          py: 1,
          borderRadius: 1,
        }}
      ></Box>

      {borderColor === "border.success" ? (
        <Box sx={{ color: "icon.success", py: 3, borderRadius: 1 }}>
          <AkCheckCircleIcon />
        </Box>
      ) : (
        <Box sx={{ color: "icon.danger", py: 3, borderRadius: 1 }}>
          <AkCrossCircleIcon />
        </Box>
      )}

      <Box
        sx={{
          "& *:last-child": { mb: 0 },
          img: { maxWidth: "100%" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </>
  );
};
