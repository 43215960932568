/* eslint-disable @typescript-eslint/ban-ts-comment */

// existential getter function that looks up
// dot-delimited styles

// this is a temporary hand-built type until we migrate @fuegokit/tokens to TS fully and separate colors and shadows there, at the source
// once we do that we won't need to use the partitionColors function in theme-utils and theme-preval

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// @ts-ignore

// @ts-ignore

// @ts-ignore

// brand colors introduce `.inverse` token modifiers; this ensures we support them and product use cases

const get = (theme, token, fallback) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const key = token && token.split ? token.split('.') : [token];
  for (let i = 0; i < key.length; i++) {
    theme = theme ? theme[key[i]] : undefined;
  }
  return theme === undefined ? fallback : theme;
};
const themeGet = (token, fallback = null) =>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
props => get(props.theme, token, fallback);

export { themeGet as default, get, themeGet };
