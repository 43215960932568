/**
 * @fuegokit/tokens 0.24.0
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 12:21:15 GMT
 */

export default {
  scale: {
    gray: {
      "100": "#D8D8D8",
      "200": "#CCCCCC",
      "300": "#BDBDBD",
      "400": "#ADADAD",
      "500": "#969696",
      "600": "#7D7D7D",
      "700": "#636363",
      "800": "#4A4A4A",
      "900": "#303030",
      "000": "#F2F2F2",
    },
    neutral: {
      "100": "#181B34",
      "200": "#292F4C",
      "250": "#2B3150",
      "300": "#30324E",
      "350": "#343755",
      "400": "#3C3F59",
      "500": "#4B4E69",
      "600": "#797E93",
      "700": "#9699A6",
      "800": "#C3C6D4",
      "900": "#CACCD9",
      "1000": "#D5D8DF",
      "1100": "#FFFFFF",
      "000": "#323338",
    },
    neutralAlpha: {
      "100": "#ffffff0a",
      "200": "#ffffff0f",
      "250": "#ffffff12",
      "300": "#ffffff14",
      "350": "#ffffff1a",
      "400": "#ffffff26",
      "500": "#ffffff33",
      "600": "#ffffff4d",
      "700": "#ffffff99",
      "800": "#ffffffb3",
      "900": "#ffffffcc",
      "000": "#ffffff00",
    },
    blue: {
      "100": "#AED4FC",
      "200": "#67AEF9",
      "300": "#1E89FA",
      "400": "#0073EA",
      "500": "#1F76DB",
      "600": "#1F76C2",
      "700": "#0060B9",
      "800": "#003D75",
      "900": "#00284D",
      "000": "#CCE5FF",
    },
    teal: {
      "100": "#C1EBF1",
      "200": "#8BDAE4",
      "300": "#6ACFDC",
      "400": "#38BFD1",
      "500": "#2696A6",
      "600": "#1D717C",
      "700": "#175A63",
      "800": "#12484f",
      "900": "#0E393E",
      "000": "#D6F2F5",
    },
    purple: {
      "100": "#E7D5F6",
      "200": "#D0AEED",
      "300": "#B781E4",
      "400": "#A25DDC",
      "500": "#904ACF",
      "600": "#8050AB",
      "700": "#68418B",
      "800": "#4E3168",
      "900": "#342145",
      "000": "#F3EAFA",
    },
    green: {
      "100": "#BBDBC9",
      "200": "#B5CEC0",
      "300": "#89BDA0",
      "400": "#33995F",
      "500": "#00854D",
      "600": "#007A41",
      "700": "#007038",
      "800": "#006130",
      "900": "#004D26",
      "000": "#D6EBDF",
    },
    yellow: {
      "100": "#FFE580",
      "200": "#FBD850",
      "300": "#FFD329",
      "400": "#F5C300",
      "500": "#E5B700",
      "600": "#DBAF00",
      "700": "#C29E11",
      "800": "#755F0A",
      "900": "#503E02",
      "000": "#FFEFB2",
    },
    red: {
      "100": "#F4C3CB",
      "200": "#ECB7BF",
      "300": "#E06C7D",
      "400": "#DD5569",
      "500": "#D83A52",
      "600": "#C53A4C",
      "700": "#B63546",
      "800": "#76232E",
      "900": "#4B161D",
      "000": "#FBE9EC",
    },
    orange: {
      "100": "#FCEBA1",
      "200": "#F2D973",
      "300": "#FFCB00",
      "400": "#F5C238",
      "500": "#EAAA15",
      "600": "#C58F12",
      "700": "#AA7909",
      "800": "#7C5803",
      "900": "#5A3F02",
      "000": "#FDF4CE",
    },
    magenta: {
      "100": "#FFC2E0",
      "200": "#FF8AC4",
      "300": "#FF5CAD",
      "400": "#FF3399",
      "500": "#FF158A",
      "600": "#E1197E",
      "700": "#C21E71",
      "800": "#610F39",
      "900": "#4B0C2C",
      "000": "#FFE5F2",
    },
    lime: {
      "100": "#E0F1BB",
      "200": "#CDE992",
      "300": "#B9E066",
      "400": "#A8D841",
      "500": "#9CD326",
      "600": "#89BA21",
      "700": "#7CA32B",
      "800": "#4D651B",
      "900": "#2E3D10",
      "000": "#ECF6D5",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#181B34",
        hovered: "#181B34",
        pressed: "#292F4C",
      },
      sunken: "#30324E",
      raised: {
        default: "#292F4C",
        hovered: "#2B3150",
        pressed: "#30324E",
      },
      overlay: {
        default: "#292F4C",
        hovered: "#30324E",
        pressed: "#30324E",
      },
    },
    shadow: {
      raised: "0px 1px 2px 0px #090b1980",
      overlay: "0px 4px 8px 0px #090b1980",
      overflow: {
        default: "0px 6px 20px 0px #090b1980",
        spread: "#ffffff14",
        perimeter: "#ffffff0f",
      },
    },
  },
  border: {
    default: "#4B4E69",
    bold: "#797E93",
    selected: "#0073EA",
    focused: "#67AEF9",
    subtle: "#797E93",
    input: "#4B4E69",
    inverse: "#323338",
    disabled: "#ffffff0f",
    brand: "#67AEF9",
    danger: "#DD5569",
    warning: "#F5C238",
    success: "#33995F",
    discovery: "#D0AEED",
    information: "#0073EA",
    accent: {
      blue: "#0073EA",
      red: "#DD5569",
      orange: "#F5C238",
      yellow: "#FFD329",
      green: "#33995F",
      purple: "#D0AEED",
      teal: "#38BFD1",
      magenta: "#FF8AC4",
      gray: "#797E93",
    },
    table: {
      default: "#ffffff14",
      container: "#4B4E69",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#00284D",
        subtler: "#003D75",
        subtle: "#0060B9",
        bolder: "#1E89FA",
      },
      red: {
        subtlest: "#4B161D",
        subtler: "#76232E",
        subtle: "#B63546",
        bolder: "#E06C7D",
      },
      orange: {
        subtlest: "#5A3F02",
        subtler: "#7C5803",
        subtle: "#AA7909",
        bolder: "#F5C238",
      },
      yellow: {
        subtlest: "#503E02",
        subtler: "#755F0A",
        subtle: "#C29E11",
        bolder: "#FFD329",
      },
      green: {
        subtlest: "#004D26",
        subtler: "#006130",
        subtle: "#007038",
        bolder: "#89BDA0",
      },
      purple: {
        subtlest: "#342145",
        subtler: "#4E3168",
        subtle: "#68418B",
        bolder: "#B781E4",
      },
      teal: {
        subtlest: "#0E393E",
        subtler: "#12484f",
        subtle: "#175A63",
        bolder: "#6ACFDC",
      },
      magenta: {
        subtlest: "#4B0C2C",
        subtler: "#610F39",
        subtle: "#C21E71",
        bolder: "#FF5CAD",
      },
      gray: {
        subtlest: {
          default: "#30324E",
          hovered: "#292F4C",
          pressed: "#181B34",
        },
        subtler: {
          default: "#3C3F59",
          hovered: "#30324E",
          pressed: "#292F4C",
        },
        subtle: {
          default: "#4B4E69",
          hovered: "#3C3F59",
          pressed: "#30324E",
        },
        bolder: {
          default: "#9699A6",
          hovered: "#797E93",
          pressed: "#4B4E69",
        },
      },
      lime: {
        subtlest: "#2E3D10",
        subtler: "#4D651B",
        subtle: "#7CA32B",
        bolder: "#B9E066",
      },
    },
    input: {
      default: "#181B34",
      hovered: "#292F4C",
      pressed: "#181B34",
    },
    inverse: {
      subtle: {
        default: "#FFFFFF29",
        hovered: "#FFFFFF3D",
        pressed: "#FFFFFF52",
      },
    },
    brand: {
      subtlest: {
        default: "#00284D",
        hovered: "#003D75",
        pressed: "#0060B9",
      },
      bold: {
        default: "#0073EA",
        hovered: "#1E89FA",
        pressed: "#67AEF9",
      },
      boldest: {
        default: "#CCE5FF",
        hovered: "#AED4FC",
        pressed: "#67AEF9",
      },
    },
    neutral: {
      default: {
        "[default]": "#ffffff0f",
        hovered: "#ffffff14",
        pressed: "#ffffff26",
      },
      subtle: {
        default: "#ffffff00",
        hovered: "#ffffff0f",
        pressed: "#ffffff14",
      },
      bold: {
        default: "#C3C6D4",
        hovered: "#CACCD9",
        pressed: "#D5D8DF",
      },
    },
    selected: {
      default: {
        "[default]": "#00284D",
        hovered: "#003D75",
        pressed: "#003D75",
      },
      bold: {
        default: "#1E89FA",
        hovered: "#67AEF9",
        pressed: "#AED4FC",
      },
    },
    disabled: "#3C3F59",
    information: {
      default: {
        "[default]": "#00284D",
        hovered: "#003D75",
        pressed: "#0060B9",
      },
      bold: {
        default: "#1E89FA",
        hovered: "#67AEF9",
        pressed: "#AED4FC",
      },
    },
    danger: {
      default: {
        "[default]": "#76232E",
        hovered: "#B63546",
        pressed: "#C53A4C",
      },
      bold: {
        default: "#E06C7D",
        hovered: "#ECB7BF",
        pressed: "#F4C3CB",
      },
    },
    success: {
      default: {
        "[default]": "#004D26",
        hovered: "#006130",
        pressed: "#007038",
      },
      bold: {
        default: "#89BDA0",
        hovered: "#B5CEC0",
        pressed: "#BBDBC9",
      },
    },
    discovery: {
      default: {
        "[default]": "#342145",
        hovered: "#4E3168",
        pressed: "#68418B",
      },
      bold: {
        default: "#B781E4",
        hovered: "#D0AEED",
        pressed: "#E7D5F6",
      },
    },
    warning: {
      default: {
        "[default]": "#503E02",
        hovered: "#755F0A",
        pressed: "#C29E11",
      },
      bold: {
        default: "#FFD329",
        hovered: "#FBD850",
        pressed: "#FFE580",
      },
    },
  },
  blanket: {
    default: "#ffffff26",
    selected: "#1f76c21a",
    danger: "#b635461a",
  },
  text: {
    default: "#D5D8DF",
    subtle: "#9699A6",
    subtlest: "#C3C6D4",
    disabled: "#797E93",
    selected: "#67AEF9",
    inverse: "#323338",
    success: "#89BDA0",
    danger: "#ECB7BF",
    information: "#67AEF9",
    warning: {
      default: "#F5C300",
      inverse: "#323338",
    },
    discovery: "#D0AEED",
    brand: "#67AEF9",
    accent: {
      blue: {
        default: "#67AEF9",
        bolder: "#CCE5FF",
      },
      red: {
        default: "#ECB7BF",
        bolder: "#FBE9EC",
      },
      orange: {
        default: "#FFCB00",
        bolder: "#FDF4CE",
      },
      yellow: {
        default: "#FBD850",
        bolder: "#FFEFB2",
      },
      green: {
        default: "#B5CEC0",
        bolder: "#D6EBDF",
      },
      purple: {
        default: "#D0AEED",
        bolder: "#F3EAFA",
      },
      teal: {
        default: "#8BDAE4",
        bolder: "#D6F2F5",
      },
      magenta: {
        default: "#FF8AC4",
        bolder: "#FFE5F2",
      },
      gray: {
        default: "#C3C6D4",
        bolder: "#D5D8DF",
      },
      lime: {
        default: "#B9E066",
        bolder: "#E0F1BB",
      },
    },
  },
  link: {
    default: "#AED4FC",
    hovered: "#AED4FC",
    pressed: "#CCE5FF",
    visited: "#AED4FC",
  },
  icon: {
    accent: {
      blue: "#67AEF9",
      red: "#DD5569",
      orange: "#EAAA15",
      yellow: "#E5B700",
      green: "#89BDA0",
      purple: "#B781E4",
      teal: "#38BFD1",
      magenta: "#E1197E",
      gray: "#797E93",
      lime: "#89BA21",
    },
    default: "#C3C6D4",
    subtle: "#9699A6",
    inverse: "#323338",
    disabled: "#4B4E69",
    brand: "#67AEF9",
    selected: "#67AEF9",
    danger: "#DD5569",
    success: "#89BDA0",
    discovery: "#B781E4",
    information: "#67AEF9",
    warning: {
      default: "#EAAA15",
      inverse: "#323338",
    },
  },
  interaction: {
    hovered: "#ffffff14",
    pressed: "#ffffff26",
  },
  skeleton: {
    default: "#ffffff0f",
    subtle: "#ffffff0a",
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  chart: {
    brand: {
      default: "#0073EA",
      hovered: "#1E89FA",
    },
    neutral: {
      default: "#797E93",
      hovered: "#9699A6",
    },
    success: {
      default: {
        "[default]": "#33995F",
        hovered: "#89BDA0",
      },
      bold: {
        default: "#B5CEC0",
        hovered: "#BBDBC9",
      },
    },
    danger: {
      default: {
        "[default]": "#D83A52",
        hovered: "#DD5569",
      },
      bold: {
        default: "#ECB7BF",
        hovered: "#F4C3CB",
      },
    },
    warning: {
      default: {
        "[default]": "#EAAA15",
        hovered: "#F5C238",
      },
      bold: {
        default: "#FFCB00",
        hovered: "#F2D973",
      },
    },
    information: {
      default: {
        "[default]": "#1F76DB",
        hovered: "#1E89FA",
      },
      bold: {
        default: "#67AEF9",
        hovered: "#AED4FC",
      },
    },
    discovery: {
      default: {
        "[default]": "#904ACF",
        hovered: "#A25DDC",
      },
      bold: {
        default: "#D0AEED",
        hovered: "#E7D5F6",
      },
    },
    categorical: {
      "1": {
        default: "#2696A6",
        hovered: "#38BFD1",
      },
      "2": {
        default: "#D0AEED",
        hovered: "#E7D5F6",
      },
      "3": {
        default: "#EAAA15",
        hovered: "#F5C238",
      },
      "4": {
        default: "#FF8AC4",
        hovered: "#FFC2E0",
      },
      "5": {
        default: "#AED4FC",
        hovered: "#CCE5FF",
      },
      "6": {
        default: "#904ACF",
        hovered: "#A25DDC",
      },
      "7": {
        default: "#FFC2E0",
        hovered: "#FFE5F2",
      },
      "8": {
        default: "#F2D973",
        hovered: "#FCEBA1",
      },
    },
    blue: {
      bold: {
        default: "#1F76DB",
        hovered: "#0073EA",
      },
      bolder: {
        default: "#0073EA",
        hovered: "#1E89FA",
      },
      boldest: {
        default: "#67AEF9",
        hovered: "#AED4FC",
      },
    },
    red: {
      bold: {
        default: "#D83A52",
        hovered: "#DD5569",
      },
      bolder: {
        default: "#DD5569",
        hovered: "#E06C7D",
      },
      boldest: {
        default: "#ECB7BF",
        hovered: "#F4C3CB",
      },
    },
    orange: {
      bold: {
        default: "#F5C238",
        hovered: "#FFCB00",
      },
      bolder: {
        default: "#FFCB00",
        hovered: "#F2D973",
      },
      boldest: {
        default: "#F2D973",
        hovered: "#FCEBA1",
      },
    },
    yellow: {
      bold: {
        default: "#F5C300",
        hovered: "#FFD329",
      },
      bolder: {
        default: "#FFD329",
        hovered: "#FBD850",
      },
      boldest: {
        default: "#FBD850",
        hovered: "#FFE580",
      },
    },
    green: {
      bold: {
        default: "#33995F",
        hovered: "#89BDA0",
      },
      bolder: {
        default: "#89BDA0",
        hovered: "#B5CEC0",
      },
      boldest: {
        default: "#B5CEC0",
        hovered: "#BBDBC9",
      },
    },
    teal: {
      bold: {
        default: "#38BFD1",
        hovered: "#6ACFDC",
      },
      bolder: {
        default: "#6ACFDC",
        hovered: "#8BDAE4",
      },
      boldest: {
        default: "#8BDAE4",
        hovered: "#C1EBF1",
      },
    },
    purple: {
      bold: {
        default: "#904ACF",
        hovered: "#A25DDC",
      },
      bolder: {
        default: "#A25DDC",
        hovered: "#B781E4",
      },
      boldest: {
        default: "#D0AEED",
        hovered: "#E7D5F6",
      },
    },
    magenta: {
      bold: {
        default: "#FF158A",
        hovered: "#FF3399",
      },
      bolder: {
        default: "#FF3399",
        hovered: "#FF5CAD",
      },
      boldest: {
        default: "#FF8AC4",
        hovered: "#FFC2E0",
      },
    },
    gray: {
      bold: {
        default: "#4B4E69",
        hovered: "#797E93",
      },
      bolder: {
        default: "#797E93",
        hovered: "#9699A6",
      },
      boldest: {
        default: "#9699A6",
        hovered: "#C3C6D4",
      },
    },
    lime: {
      bold: {
        default: "#9CD326",
        hovered: "#B9E066",
      },
      bolder: {
        default: "#B9E066",
        hovered: "#CDE992",
      },
      boldest: {
        default: "#CDE992",
        hovered: "#E0F1BB",
      },
    },
  },
};
