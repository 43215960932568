/**
 * @fuegokit/tokens 0.24.0
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 12:21:15 GMT
 */

export default {
  size: {
    "0": "0px",
    "4": "4px",
    "8": "8px",
    "12": "12px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "28": "28px",
    "32": "32px",
    "36": "36px",
    "40": "40px",
    "44": "44px",
    "48": "48px",
    "64": "64px",
    "80": "80px",
    "96": "96px",
    "112": "112px",
    "128": "128px",
    "144": "144px",
  },
  space: {
    "0": "0px",
    "1": "4px",
    "2": "8px",
    "3": "12px",
    "4": "16px",
    "5": "20px",
    "6": "24px",
    "7": "32px",
    "8": "40px",
    "9": "48px",
    "10": "56px",
    "11": "64px",
    "12": "96px",
  },
};
