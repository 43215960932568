/**
 * @fuegokit/tokens 0.24.0
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 12:21:54 GMT
 */
export default {
  fontFamily: {
    sansSerif: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    monospace: "Menlo",
    heading: 'Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    display: 'Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    body: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    normal: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    mono: "Menlo",
  },
  fontFamilies: {
    sansSerif: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    monospace: "Menlo",
    title: 'Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    body: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    normal: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    mono: "Menlo",
  },
  fontStack: {
    sansSerif: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    monospace: 'Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace',
    title: 'Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    body: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    normal: 'Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    mono: 'Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace',
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    semibold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extraBold: 700,
    black: 700,
  },
  fontSize: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "14px",
    "4": "16px",
    "5": "18px",
    "6": "24px",
    "7": "30px",
    "8": "32px",
    "9": "36px",
    "10": "42px",
    "11": "48px",
    "12": "56px",
    "13": "64px",
  },
  fontSizes: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "14px",
    "4": "16px",
    "5": "18px",
    "6": "24px",
    "7": "30px",
    "8": "32px",
    "9": "36px",
    "10": "42px",
    "11": "48px",
    "12": "56px",
    "13": "64px",
  },
  lineHeight: {
    "0": "16px",
    "1": "18px",
    "2": "24px",
    "3": "32px",
    "4": "40px",
    "5": "42px",
    "6": "48px",
    default: 1.5,
    condensed: 1.4,
    condensedUltra: 1.125,
    relaxed: 1.6,
  },
  lineHeights: {
    "0": "16px",
    "1": "18px",
    "2": "24px",
    "3": "32px",
    "4": "40px",
    "5": "42px",
    "6": "48px",
    default: 1.5,
    condensed: 1.4,
    condensedUltra: 1.125,
    relaxed: 1.6,
  },
  letterSpacing: {
    "0": "0px",
    "1": "-0.08px",
    "2": "-0.1px",
    "3": "-0.25px",
    "4": "-0.5px",
  },
  font: {
    heading: {
      xxsmall: '600 12px/16px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      xsmall: '600 14px/16px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      small: '600 16px/18px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      medium: '600 18px/24px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      large: '600 24px/32px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      xlarge: '600 32px/40px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      xxlarge: '600 36px/40px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    },
    body: {
      "[default]": '400 14px/18px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      small: '400 12px/16px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      medium: '400 14px/18px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      large: '400 16px/24px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      xlarge: '400 18px/24px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    },
    ui: {
      "[default]": '600 14px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      small: '600 12px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    },
    caption: {
      "[default]": '400 12px/16px Figtree, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    },
    code: {
      "[default]": "400 14px Menlo",
      xsmall: "400 12px Menlo",
      small: "400 14px Menlo",
      medium: "400 16px Menlo",
    },
    display: {
      "[default]": '400 64px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
      medium: '400 56px Poppins, Roboto, Rubik, "Noto Kufi Arabic", "Noto Sans JP", sans-serif',
    },
  },
};
