/**
 * @fuegokit/tokens 0.24.0
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 12:21:17 GMT
 */

export default {
  scale: {
    orange: {
      "100": "#FFD6C4",
      "200": "#FFAD89",
      "300": "#FF996C",
      "400": "#ED7C4A",
      "500": "#BE633B",
      "600": "#8E4A2C",
      "700": "#5F321E",
      "800": "#2F190F",
      "900": "#180C07",
      "000": "#FFF5F0",
    },
    blue: {
      "100": "#B0CAFF",
      "200": "#6195FF",
      "300": "#397BFF",
      "400": "#336FE6",
      "500": "#2256C5",
      "600": "#224A99",
      "700": "#173166",
      "800": "#0C1933",
      "900": "#070F1F",
      "000": "#EBF2FF",
    },
    teal: {
      "100": "#D1FEF9",
      "200": "#A4FDF4",
      "300": "#8DFCF1",
      "400": "#7FE3D9",
      "500": "#71CAC1",
      "600": "#559791",
      "700": "#386560",
      "800": "#1C3330",
      "900": "#111E1D",
      "000": "#F4FFFE",
    },
    purple: {
      "100": "#DDCBFF",
      "200": "#BA97FF",
      "300": "#A97DFF",
      "400": "#9871E6",
      "500": "#8764CC",
      "600": "#654B99",
      "700": "#443266",
      "800": "#221933",
      "900": "#140F1F",
      "000": "#F6F2FF",
    },
    white: "#FFFFFF",
    black: "#1E1E1E",
    yellow: {
      "100": "#FFFBE2",
      "200": "#FFF8C5",
      "300": "#FFF6B6",
      "400": "#FFEE70",
      "500": "#f0da4e",
      "600": "#C0AE3E",
      "700": "#90832F",
      "800": "#484117",
      "900": "#181608",
      "000": "#FFFEF8",
    },
    red: {
      "100": "#FBB4B4",
      "200": "#F66969",
      "300": "#F44343",
      "400": "#DC3C3C",
      "500": "#C33636",
      "600": "#922828",
      "700": "#621B1B",
      "800": "#310E0E",
      "900": "#1D0808",
      "000": "#FEECEC",
    },
    green: {
      "100": "#B7DFB9",
      "200": "#B7DFB9",
      "300": "#4CAF50",
      "400": "#449E48",
      "500": "#358238",
      "600": "#2E6930",
      "700": "#1E4620",
      "800": "#0F2310",
      "900": "#09150A",
      "000": "#EDF7EE",
    },
    neutral: {
      "100": "#121213",
      "200": "#1C1B1C",
      "300": "#2E2D2F",
      "400": "#5C5B5E",
      "500": "#8A888E",
      "600": "#A19FA5",
      "700": "#B8B6BD",
      "800": "#CFCCD4",
      "900": "#E6E3EC",
      "1000": "#F3F1F6",
      "1100": "#FAF9FB",
      "000": "#090A0F",
    },
    neutralAlpha: {
      "100": "#b8b6bd0f",
      "200": "#b8b6bd1a",
      "300": "#b8b6bd33",
      "350": "#b8b6bd54",
      "400": "#b8b6bd78",
      "500": "#b8b6bdba",
      "000": "#00000000",
    },
    gradient: {
      orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
      yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
      bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
      yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
      orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
      orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
      blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
      purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
      tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
      tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
    },
  },
  text: {
    brand: "#397BFF",
    inverse: "#090A0F",
    default: "#FFFFFF",
    subtle: {
      default: "#CFCCD4",
      inverse: "#5C5B5E",
    },
    subtlest: {
      default: "#A19FA5",
      inverse: "#A19FA5",
    },
    danger: {
      default: "#F44343",
      inverse: "#C33636",
    },
    warning: {
      default: {
        "[default]": "#181608",
        hovered: "#484117",
        pressed: "#90832F",
      },
      bold: {
        default: "#FFF8C5",
        hovered: "#FFF6B6",
        pressed: "#FFEE70",
      },
      inverse: "#FFFEF8",
    },
    success: {
      default: "#4CAF50",
      inverse: "#358238",
    },
    disabled: "#5C5B5E",
    selected: "#173166",
    accent: {
      blue: {
        default: "#397BFF",
        bolder: "#B0CAFF",
      },
      red: {
        default: "#F44343",
        bolder: "#FEECEC",
      },
      orange: {
        default: "#FF996C",
        bolder: "#FFD6C4",
      },
      yellow: {
        default: "#FFEE70",
        bolder: "#FFFBE2",
      },
      green: {
        default: "#4CAF50",
        bolder: "#B7DFB9",
      },
      purple: {
        default: "#9871E6",
        bolder: "#DDCBFF",
      },
      teal: {
        default: "#8DFCF1",
        bolder: "#D1FEF9",
      },
      gray: {
        default: "#8A888E",
        bolder: "#CFCCD4",
      },
    },
  },
  link: {
    default: "#397BFF",
    hovered: "#397BFF",
    pressed: "#6195FF",
    visited: "#BA97FF",
  },
  icon: {
    brand: "#397BFF",
    inverse: "#121213",
    danger: {
      default: "#F44343",
      inverse: "#C33636",
    },
    success: {
      default: "#4CAF50",
      inverse: "#358238",
    },
    warning: {
      default: "#BE633B",
      inverse: "#F3F1F6",
    },
    disabled: "#8A888E",
    accent: {
      blue: {
        default: "#397BFF",
        bolder: "#B0CAFF",
      },
      red: {
        default: "#F44343",
        bolder: "#FEECEC",
      },
      orange: {
        default: "#FF996C",
        bolder: "#FFD6C4",
      },
      yellow: {
        default: "#FFEE70",
        bolder: "#FFFBE2",
      },
      green: {
        default: "#4CAF50",
        bolder: "#B7DFB9",
      },
      purple: {
        default: "#9871E6",
        bolder: "#DDCBFF",
      },
      teal: {
        default: "#7FE3D9",
        bolder: "#D1FEF9",
      },
      gray: {
        default: "#5C5B5E",
        bolder: "#CFCCD4",
      },
    },
    default: "#FFFFFF",
  },
  border: {
    brand: "#397BFF",
    inverse: "#090A0F",
    focused: {
      default: "#6195FF",
      inverse: "#173166",
    },
    danger: {
      default: "#F44343",
      inverse: "#C33636",
    },
    success: {
      default: "#4CAF50",
      inverse: "#358238",
    },
    input: {
      default: "#FFFFFF",
      hovered: "#397BFF",
    },
    disabled: "#b8b6bd78",
    default: "#FFFFFF",
    bold: "#A19FA5",
    accent: {
      blue: "#397BFF",
      red: "#F44343",
      orange: "#FF996C",
      yellow: "#FFEE70",
      green: "#4CAF50",
      purple: "#A97DFF",
      teal: "#7FE3D9",
      gray: "#8A888E",
    },
    subtle: {
      default: "#5C5B5E",
      inverse: "#CFCCD4",
    },
    table: {
      default: "#b8b6bd33",
      container: "#00000000",
    },
  },
  background: {
    brand: {
      bold: {
        default: "#336FE6",
        hovered: "#2256C5",
        pressed: "#224A99",
      },
      default: {
        "[default]": "#336FE6",
        hovered: "#397BFF",
        pressed: "#6195FF",
      },
    },
    disabled: "#b8b6bd1a",
    success: {
      default: "#0F2310",
      inverse: "#EDF7EE",
    },
    danger: {
      default: {
        "[default]": "#310E0E",
        hovered: "#621B1B",
        pressed: "#922828",
      },
      bold: {
        default: "#F44343",
        hovered: "#F66969",
        pressed: "#FBB4B4",
      },
      inverse: "#FEECEC",
    },
    warning: {
      default: {
        "[default]": "#484117",
        hovered: "#90832F",
        pressed: "#C0AE3E",
      },
      bold: {
        default: "#FFF6B6",
        hovered: "#FFF8C5",
        pressed: "#FFFBE2",
      },
      inverse: "#FFFEF8",
    },
    accent: {
      blue: {
        subtlest: "#0C1933",
        subtler: "#224A99",
        subtle: "#2256C5",
        bolder: "#397BFF",
      },
      red: {
        subtlest: "#310E0E",
        subtler: "#621B1B",
        subtle: "#922828",
        bolder: "#F44343",
      },
      orange: {
        subtlest: "#2F190F",
        subtler: "#8E4A2C",
        subtle: "#BE633B",
        bolder: "#FF996C",
      },
      yellow: {
        subtlest: "#484117",
        subtler: "#C0AE3E",
        subtle: "#f0da4e",
        bolder: "#FFF6B6",
      },
      green: {
        subtlest: "#0F2310",
        subtler: "#2E6930",
        subtle: "#358238",
        bolder: "#4CAF50",
      },
      teal: {
        subtlest: "#1C3330",
        subtler: "#559791",
        subtle: "#71CAC1",
        bolder: "#8DFCF1",
      },
      purple: {
        subtlest: "#221933",
        subtler: "#654B99",
        subtle: "#8764CC",
        bolder: "#A97DFF",
      },
      gray: {
        subtlest: {
          default: "#1C1B1C",
          hovered: "#121213",
          pressed: "#090A0F",
        },
        subtler: {
          default: "#2E2D2F",
          hovered: "#1C1B1C",
          pressed: "#121213",
        },
        subtle: {
          default: "#8A888E",
          hovered: "#5C5B5E",
          pressed: "#2E2D2F",
        },
        bolder: {
          default: "#B8B6BD",
          hovered: "#A19FA5",
          pressed: "#8A888E",
        },
      },
      gradient: {
        orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
        yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
        bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
        yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
        orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
        orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
        blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
        purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
        tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
        tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
      },
    },
    neutral: {
      subtle: {
        default: "#00000000",
        hovered: "#b8b6bd1a",
        pressed: "#b8b6bd33",
      },
    },
    selected: {
      default: {
        "[default]": "#6195FF",
        hovered: "#397BFF",
        pressed: "#336FE6",
      },
      bold: {
        default: "#224A99",
        hovered: "#173166",
        pressed: "#0C1933",
      },
    },
  },
  surface: {
    default: "#090A0F",
    inverse: "#FFFFFF",
    raised: {
      default: "#121213",
      hovered: "#1C1B1C",
      pressed: "#2E2D2F",
    },
  },
  elevation: {
    surface: {
      inverse: {
        default: "#FFFFFF",
      },
      default: {
        "[default]": "#090A0F",
        hovered: "#1C1B1C",
        pressed: "#2E2D2F",
      },
      raised: {
        default: "#121213",
        hovered: "#1C1B1C",
        pressed: "#2E2D2F",
      },
    },
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  skeleton: {
    default: "#b8b6bd1a",
    subtle: "#b8b6bd0f",
  },
};
